



























































































































































import { Component, Prop } from 'vue-property-decorator'

import { CartItemMixin } from '../../../../shared/mixins/cartItem.mixin'

import QuantityPicker from '../../QuantityPicker/QuantityPicker.vue'

import { Action } from '../CartItem.contracts'
import { CartItemAction } from '../CartItem.config'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<BasicCartItem>({
  name: 'BasicCartItem',
  components: { QuantityPicker },
  beforeDestroy (): void {
    this.notify('isLoading', false)
  }
})
export class BasicCartItem extends CartItemMixin {
  @Prop({ required: false, type: Boolean, default: false })
  public shouldDisablePerItemQuantity?: boolean

  public get calculatedPercentageDiscount (): number {
    if (this.finalPromotionPrice) {
      const calculated = (1 - (this.finalPromotionPrice / this.finalPrice)) * 100
      return Math.round(calculated)
    }

    return 0
  }

  public get calculatedPricePerItem (): number | null {
    if (this.finalPromotionPrice) {
      if (this.shouldDisableQuantity) {
        if (this.quantity && this.quantity > 1) {
          return this.finalPromotionPrice / this.quantity
        }

        return null
      } else {
        if (this.quantity) {
          return this.finalPromotionPrice / this.quantity
        }

        return null
      }
    } else {
      if (this.shouldDisableQuantity) {
        if (this.quantity && this.quantity > 1) {
          return this.finalPrice / this.quantity
        }

        return null
      } else {
        if (this.quantity) {
          return this.finalPrice / this.quantity
        }

        return null
      }
    }
  }

  public get deleteAction (): Action[] | undefined {
    const actions = []
    if (!this.actions) {
      return
    }

    for (const action of this.actions) {
      if (action.key === CartItemAction.Delete) {
        actions.push(action)
      }
    }

    return actions
  }
}

export default BasicCartItem
